/*================ About Image =================*/
.about_container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

/* h1 {
  display: block;
  overflow: hidden; Ensures the content is not revealed until the animation
  border-right: 0.15em solid var(--color-bg); The typwriter cursor
  white-space: nowrap; Keeps the content on a single line
  margin: 0 auto; Gives that scrolling effect as the typing happens
  letter-spacing: 0.15em; Adjust as needed
  animation: typing 10s steps(40, end), blink-caret 5s steps(30, end);
} */

/* The typing effect */
/* @keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
} */

/* The typewriter cursor effect */
/* @keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--color-bg-variant);
  }
} */

.about_me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
}

.about_me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}

.about_me-image:hover {
  transform: rotate(0deg);
}

.cta-socials {
  margin-top: 2rem;
  display: flex;
  gap: 1.2rem;
  justify-content: left;
}

.cta-socials a {
  background: var(--color-bg-variant);
  padding: 1.1rem;
  border-radius: 50%;
  display: flex;
  justify-content: space-evenly;
  color: var(--color-primary);
  font-size: 1.1rem;
  animation: 5s wiggle ease;
  animation-iteration-count: 3;
}

@keyframes wiggle {
  0% {
    transform: rotate(-3deg);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
}

.cta-socials a:hover {
  background: var(--color-bg);
}

.cta-socials a.active {
  background: var(--color-primary);
  color: var(--color-bg);
}

.tagline {
  text-align: center;
  color: var(--color-primary);
}

/* ================== About Cards ================*/

.about_cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about_card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align-last: center;
  transition: var(--transition);
}

.about_card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about_icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about_card h5 {
  font-size: 0.95rem;
}

.about_card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about_content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

/*============= Media Queries (Medium Devices) ==============*/
@media screen and (max-width: 1024px) {
  .about_container {
    grid-template-columns: 1fr;
    gap: 0;
    margin-top: 15%;
  }

  .about_me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about_content p {
    margin: 1rem 0 1.5rem;
  }
}

/*============= Media Queries (Small Devices) ==============*/
@media screen and (max-width: 600px) {
  .about_me {
    width: 65%;
    margin: 0 auto 3rem;
  }

  .about_cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about_content {
    text-align: center;
  }

  .about_content p {
    margin: 1.5rem 0;
  }
}
